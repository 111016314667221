@import url("https://fonts.googleapis.com/css?family=Gugi|Raleway|Kanit");
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background: #151515;
  font-family: "Relative", mono, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@keyframes Line {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes Ball {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  50% {
    transform: translateY(12.5px);
    opacity: 1;
  }

  100% {
    transform: translateY(25px);
    opacity: 0;
  }
}

#tsparticles canvas {
  position: absolute !important;
}

.swiper-pagination {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.25);
  margin: 5px;
  cursor: pointer;
}
.swiper-pagination-bullet-active{
  background: #FFF;
}
